import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { HashRouter as Router } from "react-router-dom";
import { Helmet } from 'react-helmet';

import reducers from './reducers';
import DefaultLoad from './components/Loading/DefaultLoad'

const Dashboard = lazy(() => import('./Dashboard'));

const store = createStore(reducers, compose(applyMiddleware(thunk)));

ReactDOM.render(
    <Provider store={store}>
        <Helmet>
          <title>Vilkkoni | Control Panel</title>
        </Helmet>
        <Router>
            <Suspense fallback={<DefaultLoad />}>
                <Dashboard />
            </Suspense>
        </Router>
    </Provider>,
    document.getElementById('root')
);