import { FETCH_NEWS, CREATE_NEWS } from '../constants/actionTypes'

const news_reducer = (news = [], action) => {
    switch (action.type) {
        case FETCH_NEWS:
            return action.payload.slice().sort((a,b)=>new Date(a.createdAt) - new Date(b.createdAt));
        default:
            return news;
    }
}

export default news_reducer;