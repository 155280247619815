import { FETCH_ALL, CREATE, UPDATE, DELETE, DELETE_PART } from '../constants/actionTypes'

const event_reducer = (events = [], action) => {
    switch (action.type) {
        case FETCH_ALL:
            return action.payload.slice().sort((a,b)=>new Date(a.beginDate) - new Date(b.beginDate));
        case CREATE:
            return [ ...events, action.payload ];
        case UPDATE:
            return events.map((event) => event._id === action.payload._id ? action.payload : event);
        case DELETE:
            return events.filter((event) => event._id !== action.payload);
        case DELETE_PART:
            return events.map((event) => event._id === action.payload.event ? {...event, participants: event.participants.filter((part) => !action.payload.parts.includes(part._id))} : event);
        default:
            return events;
    }
}

export default event_reducer;